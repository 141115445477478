<template>
  <div class="bg-white min-h-100">
    <div class="px-100 py-34">
      <DiffMatchHeader :title="headerTitle" :subtitle="headerSubtitle" />
    </div>

    <div class="px-100">
      <ImageIndicator :step="step" :title="stepTitle" :subtitle="stepSubtitle"/>
    </div>
    <main class="px-100 pt-38">
      <ImageBody />
    </main>

    <div class="px-100 pt-50 pb-20">
      <DiffMatchFooter />
    </div>
  </div>
</template>

<script>
import DiffMatchHeader from '@/components/diffmatch/Header';
import DiffMatchFooter from '@/components/diffmatch/Footer';
import ImageIndicator from '@/components/diffmatch/ImageIndicator';
import ImageBody from '@/components/diffmatch/ImageBody';

export default ({
  components: {
    DiffMatchHeader,
    DiffMatchFooter,
    ImageIndicator,
    ImageBody
  },
  data () {
    return {
      headerTitle: 'Images comparison',
      headerSubtitle: 'This is a microservice that allows you to find faces in a image or a group of images',
      step: 1,
      stepTitle: 'Upload a group of images',
      stepSubtitle: 'In these images we will be able to find a reference face for you'
    };
  }
});
</script>
