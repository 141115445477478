<template>
  <form>
    <button v-if="file" class="bttn-primary w-260px mt-17 mb-57" @click.prevent="sendFile" :disabled="file !== 'ready'">Next</button>

    <div v-if="file === 'ready'" class="d-flex jc-space-between">
      <div>
        <p class="menu-text color-warning">Stop loading</p>
      </div>
      <div>
        <span class="text-caption-small">Download more</span>
        <Icon name="upload-arrow" class="text-lighter"/>
      </div>
    </div>

    <div class="dropzone-previews dropzone mansonry-style bg-warning" :class="{'d-none': file !== 'ready'}"></div>

    <div v-if="file !== 'ready'" id="findface-dropzone" class="w-100 h-400px bg-form-darker bg-white_hover b-all bc-form-border">
        <div v-if="file === 'loading'" class="h-100 d-flex fd-column jc-center ai-center">
        <Icon name="loading" class="color-link" id="rotate"/>
        <p class="menu-text color-text-lighter mt-4">Loading...</p>
        <p class="menu-text color-link-darker mt-24 cursor-pointer" @click="cancelUpload">Stop download</p>
        <p class="menu-text color-text-lighter mt-24">Supported file formats: .png and .jpeg. Max size: 1 MB. You can upload no more than 10 photos</p>
      </div>

      <div v-if="!file" class="h-100 d-flex fd-column jc-center ai-center">
        <Icon name="upload-arrow" class="color-link"/>
        <h4 class="text-h4 mt-25">Upload a group of images</h4>
        <p class="menu-title color-text-lighter mt-8">Supported file formats: .png and .jpeg. Max size: 1 MB. You can upload no more than 10 photos.</p>
        <p class="menu-title color-text-lighter mt-24"><span class="color-link cursor-pointer" id="dropzoneClick">Choose a file</span> or drag to here</p>
      </div>
    </div>
  </form>
</template>

<script>
import { Dropzone } from 'dropzone';
import Icon from '@/components/app/Icon.vue';

export default ({
  name: 'ImageBody',
  components: {
    Icon
  },
  data () {
    return {
      file: false
    };
  },
  mounted () {
    const dropzone = new Dropzone('div#findface-dropzone', {
      url: 'http://localhost:5000/item',
      autoProcessQueue: false,
      uploadMultiple: true,
      maxFiles: 10,
      parallelUploads: 10,
      clickable: '#dropzoneClick',
      acceptedFiles: 'image/jpeg,image/png',
      previewsContainer: '.dropzone-previews',
      thumbnailWidth: null,
      thumbnailHeight: null,
      addRemoveLinks: true
    });
    dropzone.on('addedfile', file => {
      console.log('file was drag');
      this.file = 'loading';
    });
    dropzone.on('thumbnail', file => {
      file._removeLink.innerHTML = '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#fff" /><path d="M8.25 16.38c0 .68.56 1.25 1.25 1.25h5c.69 0 1.25-.57 1.25-1.25v-7.5h-7.5v7.5zM16.38 7h-2.2l-.62-.63h-3.12L9.8 7H7.62v1.25h8.75V7z" fill="#60BCE3"/></svg>';
      if (file.name.length > 5) {
        for (const item of file.previewElement.children) {
          if (item.className === 'dz-details') item.querySelector('.dz-filename span').innerHTML = file.name.slice(0, 5) + '...';
        }
      }
      this.file = 'ready';
      console.log('thumbnail was created');
      // console.log(file);
    });
  },
  methods: {
    cancelUpload () {
      console.log('cancelUpload');
    },
    sendFile () {
      console.log('sendFile');
    }
  }
});
</script>

<style>
#rotate {
  animation: spin 0.4s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mansonry-style {
  column-count: 4;
  column-gap: 12px;
}
.dz-preview.dz-image-preview {
  position: relative;
}
.dz-filename {
  padding: 4px 16px;
  border: 1px solid #EDF5FF;
  box-sizing: border-box;
  border-radius: 16px;
  background-color: #fff;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #29292B;
}
.dz-remove {
  position: absolute;
  top: 28px;
  right: 19px;
}
.dz-details {
  position: absolute;
  top: 28px;
  left: 19px;
}
.dz-size {
  display: none;
}
.dz-image {
  background: #EEF7FC;
  border-radius: 24px;
  box-shadow: 0px 10px 40px 12px rgba(0, 0, 0, 0.02);
}
.dz-image img {
  width: 100%;
  border-radius: 24px;
}
</style>
