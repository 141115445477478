<template>
  <div class="d-flex jc-center w-100">
    <div v-for="item in [1, 2, 3]" :key='item' class="w-33 h-6px br-16 d-flex ai-center"
         :class="{
           'bg-link-darker' : step === item,
           'bg-body': step != item,
           'jc-center': item === 2,
           'jc-flex-end': item === 3
           }">
      <div class="w-32px h-32px br-32 d-flex jc-center ai-center"
           :class="step === item ? 'bg-link-darker' : 'bg-body'">
        <span class="text-link"
              :class="step === item ? 'color-white' : 'color-text'">{{ item }}</span>
        </div>
    </div>
  </div>

  <div class="text-h3 mt-33">{{ title }}</div>
  <div class="text-base color-text-darker mt-12">{{ subtitle }}</div>
</template>

<script>
export default ({
  name: 'ImageIndicator',
  props: {
    step: {
      default: 1
    },
    title: {
      default: 'Some title is here'
    },
    subtitle: {
      default: 'Some subtitle will be here'
    }
  }
});
</script>
